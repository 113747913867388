define("discourse/plugins/discourse-landing-pages/discourse/controllers/admin-plugins-landing-pages", ["exports", "discourse/plugins/discourse-landing-pages/discourse/components/modal/import-pages", "discourse/plugins/discourse-landing-pages/discourse/components/modal/update-pages-remote", "@ember/controller", "@ember/service", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/lib/ajax-error", "discourse/lib/ajax", "I18n"], function (_exports, _importPages, _updatePagesRemote, _controller, _service, _decorators, _computed, _ajaxError, _ajax, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const statusIcons = {
    error: "exclamation-triangle",
    success: "check"
  };
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    modal: (0, _service.inject)(),
    remoteDisconnected: (0, _computed.not)("remote.connected"),
    pullDisabled: (0, _computed.or)("pullingFromRemote", "remoteDisconnected"),
    fetchingCommits: false,
    commitsBehind: null,
    hasCommitsBehind: (0, _computed.gt)("commitsBehind", 0),
    hasMessages: (0, _computed.notEmpty)("messages.items"),
    showCommitsBehind: false,
    showPages: true,
    messages(staticMessage, resultMessages) {
      if (resultMessages) {
        setTimeout(() => {
          this.set("resultMessages", null);
        }, 15000);
        return {
          status: resultMessages.type,
          items: resultMessages.messages.map(message => {
            return {
              icon: statusIcons[resultMessages.type],
              text: message
            };
          })
        };
      } else if (staticMessage) {
        return {
          status: "static",
          items: [{
            icon: staticMessage.icon,
            text: staticMessage.text
          }]
        };
      } else {
        return null;
      }
    },
    staticMessage(pagesNotFetched, hasCommitsBehind, fetchingCommits, page, remote, showGlobal) {
      let key;
      let icon = "circle-info";
      if (page) {
        if (page.remote) {
          key = "page.remote.description";
          icon = "book";
        } else {
          key = "page.local.description";
          icon = "desktop";
        }
      } else if (showGlobal) {
        key = "global.description";
      } else if (remote && remote.connected) {
        if (pagesNotFetched) {
          key = "remote.repository.not_fetched";
        } else if (fetchingCommits) {
          key = "remote.repository.checking_status";
        } else if (hasCommitsBehind) {
          key = "remote.repository.out_of_date";
        } else {
          key = "remote.repository.up_to_date";
        }
      }
      if (key) {
        return {
          icon,
          text: _I18n.default.t(`admin.landing_pages.${key}`)
        };
      } else {
        return null;
      }
    },
    documentationUrl(showGlobal) {
      const rootUrl = "https://coop.pavilion.tech";
      return showGlobal ? `${rootUrl}` : `${rootUrl}`;
    },
    actions: {
      importPages() {
        this.modal.show(_importPages.default).then(result => {
          if (result?.page) {
            this.setProperties({
              pages: result.pages,
              resultMessages: {
                type: "success",
                messages: [_I18n.default.t("admin.landing_pages.imported.x_pages", {
                  count: 1
                })]
              }
            });
          }
        });
      },
      updateRemote() {
        this.modal.show(_updatePagesRemote.default, {
          model: {
            remote: this.remote
          }
        }).then(result => {
          if (result?.remote) {
            this.setProperties({
              remote: result.remote,
              pagesNotFetched: true
            });
          }
        });
      },
      pullFromRemote() {
        this.set("pullingFromRemote", true);
        (0, _ajax.ajax)("/landing/remote/pages").then(result => {
          const pages = result.pages;
          const menus = result.menus;
          const global = result.global;
          const report = result.report;
          this.setProperties({
            pages,
            menus,
            global
          });
          if (report.errors.length) {
            this.set("resultMessages", {
              type: "error",
              messages: result.report.errors
            });
          } else {
            let imported = report.imported;
            let messages = [];
            ["scripts", "menus", "assets", "pages"].forEach(listType => {
              if (imported[listType].length) {
                messages.push(_I18n.default.t(`admin.landing_pages.imported.x_${listType}`, {
                  count: imported[listType].length
                }));
              }
            });
            ["footer", "header"].forEach(boolType => {
              if (imported[boolType]) {
                messages.push(_I18n.default.t(`admin.landing_pages.imported.${boolType}`));
              }
            });
            this.setProperties({
              resultMessages: {
                type: "success",
                messages
              },
              pagesNotFetched: false
            });
            this.send("commitsBehind");
          }
        }).catch(error => {
          this.set("resultMessages", {
            type: "error",
            messages: [(0, _ajaxError.extractError)(error)]
          });
        }).finally(() => {
          this.set("pullingFromRemote", false);
        });
      },
      commitsBehind() {
        this.set("fetchingCommits", true);
        (0, _ajax.ajax)("/landing/remote/commits-behind").then(result => {
          if (!result.failed) {
            this.set("commitsBehind", result.commits_behind);
          }
        }).finally(() => {
          this.set("fetchingCommits", false);
        });
      },
      updatePages(pages) {
        this.set("pages", pages);
      },
      toggleShowPages() {
        this.setProperties({
          showPages: true,
          showGlobal: false
        });
      },
      toggleShowGlobal() {
        this.setProperties({
          showPages: false,
          showGlobal: true
        });
      }
    }
  }, [["method", "messages", [(0, _decorators.default)("staticMessage", "resultMessages")]], ["method", "staticMessage", [(0, _decorators.default)("pagesNotFetched", "hasCommitsBehind", "fetchingCommits", "page", "remote", "showGlobal")]], ["method", "documentationUrl", [(0, _decorators.default)("showGlobal")]]]));
});